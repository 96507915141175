@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.highlight:not(.djs-connection):not(.incident) .djs-visual > :nth-child(1) {
  fill: green !important;
  fill-opacity: 0.2 !important;
  outline: green !important;
  /* stroke: #35d162 !important; */
  stroke: green !important;
  opacity: 0.5;
}

.incident:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: red !important;
  fill-opacity: 0.2 !important;
  stroke: red !important;
  opacity: 0.5;
}

.active:not(.djs-connection) .djs-visual > :nth-child(1) {
  opacity: 1 !important;
}

.path-arrow .djs-visual > :nth-child(1) {
  /* stroke: green !important; */
  stroke-width: 3px !important;
  outline: #0096fe !important;
  stroke: #0096fe !important;
}

.path-activity:not(.djs-connection):not(.incident) .djs-visual > :nth-child(1) {
  /* outline: #0096fe !important;
  stroke: #0096fe !important; */
  fill: #0096fe !important;
  fill-opacity: 0.2 !important;
  outline: #0096fe !important;
  stroke: #0096fe !important;
  opacity: 0.5;
}

.shade:not(.djs-connection) .djs-visual > :nth-child(1) {
  opacity: 0.1;
}

.fjs-container .cds--grid .cds--grid .cds--row {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.info-tab-scroll-bar::-webkit-scrollbar {
  width: 0.375rem;
  margin: 0.25rem;
}

.info-tab-scroll-bar::-webkit-scrollbar-track {
  background: #fff;
}

.info-tab-scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #d6d9e3;
}

.fjs-form {
  padding: 0 !important;
}

.cds--grid {
  padding-inline: 0 !important;
}

.fjs-powered-by {
  height: 0 !important;
}

.fjs-form-field {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.fjs-input-group > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

[type='checkbox']:checked {
  border-color: #0000 !important;
  background-color: currentColor !important;
}

input[type='radio']:checked {
  border-color: #0000 !important;
  background-color: currentColor !important;
}

.no-scrollbar {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.fjs-layout-column.cds--col-lg-4 {
flex-basis: 50%;
max-inline-size: 50%;
} 

