.custom-select {
  width: 100%;
  position: relative;
}

.custom-select label {
  display: block;
  font-size: 14px;
  margin-bottom: 4px;
  color: #333;
}

.custom-select input[type="text"] {
  width: 100% !important;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s;
}

.custom-select input[type="text"]:focus {
  border-color: #007bff;
}

.options-list {
  position: absolute;
  width: 100%;
  max-height: 200px;
  margin-top: 33px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 10;
  list-style: none;
  padding: 0;
}

.options-list li {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.options-list li:hover {
  background-color: #f0f0f0;
}